@import "all";

.Mobile {
  display: none;

  .mt-6 {
    margin-top: $spacer-gap !important;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 10px;
    display: inline-block;

    .mobile-container {
      width: 100%;
      background-size: cover;
    }



  }
}
